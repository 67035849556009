@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

html{
  font-family: 'Quicksand', sans-serif;
  background-color: var(--main-background-colour);
  color: var(--main-text-colour);
}

a{
  color: var(--ion-color-primary);
  text-decoration: none;
}

a:hover{
  color: var(--ion-color-primary-tint);
}

.connections-logo{
  display: inline-block;
  float: left;
  height: 1.75em;
  padding-left: 1em;
  opacity: 1.0;
}

#container {
  position: absolute;
  left: 0;
  top: 55px;
  width: 100%;
  height: 100%;
  height: calc(100% - 55px);
  overflow: hidden;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.mapboxgl-ctrl-top-right {
  position: relative;
  top: 20px !important;
  right: 20px !important;
  /* max-width: 70% !important; */
}


/* .mapbox-gl-draw_polygon {
  background-image: url(https://icon-library.com/images/polygon-icon/polygon-icon-24.jpg) !important;
  background-color: #4c77a3 !important;
} */
/* 
.mapboxgl-ctrl button {
  background-image: url(https://static.thenounproject.com/png/4306724-200.png);
  background-size: 60%;
  background-color: #4c77a3 !important;
} */

/* .mapbox-gl-draw_ctrl-draw-btn {
  background-repeat: no-repeat;
  background-position: center;
} */

#connections-address-info{
  position: absolute;
  padding: 1em;
  margin-top: 100px;
  margin-left: 1.5em;/*calc(3em + 1em + 15px); */
  border: 1px solid var(--ion-color-secondary-shade);
  border-radius: 0.25em;
  background-color: var(--ion-color-secondary);
  max-width: 15em;
  min-height: calc(3em + 3em + 15px);
  opacity: 0.9;
  color: var(--ion-color-secondary-contrast);
}

@media screen and (max-width: 640px) {
  #connections-address-info{
    max-width:25%;
    max-height: 50%;
    font-size: x-small;
    overflow: scroll;
    white-space: nowrap;
  }
  #connections-address-info h2{
    font-size: x-small;
  }
}

@media screen and (min-width: 640px) {
  #connections-address-info{
    margin-top: 1.5em !important;
  }

  .connections-continue-button{
    font-size: 2em !important;
  }
}

.connections-info-details{
  margin-left: 0.5em !important;
  opacity: 0.85;
  /* color : var(--ion-color-tertiary-contrast) !important; */
}



#connections-address-info h2{
  font-weight: bold;
}

#logo {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 150px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  ;
  border-radius: 15px
}



.mapboxgl-popup-content {
  color: var(--ion-color-light-contrast);
  background-color:  var(--ion-color-light-tint) !important;
  border-radius: 0.25em !important;
  border: 2px solid var(--ion-color-tertiary) !important;
  padding: 1em !important;
  margin: 0.5em;
  opacity: 0.95;
  font-family: 'Quicksand', sans-serif;
}

.mapboxgl-popup-tip {
  color: #285669 !important;

}

.popup_mess {
  display: flex;
  flex-flow: column wrap;
  align-content: space-evenly;
  font-size: large;
}

.popup_mess_det {
  font-size: medium;
}

.popup_confirm {
  display: flex;
  flex-flow: column wrap;
  align-content: space-evenly;
  font-size: medium;
}

p {
  font-size: small;
}

.mapboxgl-popup-close-button {
  position: absolute;
  right: 8px;
  height: 20px;
  width: 20px;
  top: 0;
  border: 0;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.5rem;
  right : 0.25em !important;
  color: var(--ion-color-tertiary);
  display: inline;
}

.connections-popup-button{
  position: relative;
  display: inline;
  border-radius: 0.25em;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  padding: 0.5em 1em;
  gap: 0.5em;
  flex-grow: 1;
}

#confirmButton {
}

.connections-popup-button:hover {
  background-color: var(--ion-color-primary-tint);
}

#cancelButton {
}



.connections-button{
  --background: var(--ion-color-secondary);
  --background-radius: 0.25em;
  --box-shadow: none;
  width: 3em;
  height: 3em;
  margin: 1em;
}

.connections-button:hover{
  --background: var(--ion-color-secondary-tint);
}

.connections-form-group{
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  flex-wrap: wrap;
}

ion-item{
  --background: transparent;
  --inner-border-width: 0;
  border: none;
}

.connections-form-group ion-item{
  --background: var(--ion-color-light-shade) !important;
  --inner-border-width: 0;
  border: 1px solid var(--ion-color-secondary) !important;
  gap:1em;
  flex-grow: 1;
  margin: 0.25em;
}

.connections-radio{
  top: 0.2em;
}

.connections-radio-icon{
  color:var(--ion-color-secondary);
  margin: 0 0.25em;
}

.connections-radio-sublabel{
  color: var(--ion-color-light-contrast) !important;
}

#pinButton {
  position: fixed;
  margin: 15px 0 0 15px;
}

#areaButton {
  position: fixed;
  margin: 75px 0 0 15px;
}

#undoButton {
  position: fixed;
  margin: 135px 0 0 15px;
}

#styleButton {
  position: fixed;
  bottom: 1.5em;
  margin-left: 1.5em;
  /* margin: 15px 0 0 15px; */
}

#logoutButton{
  right: 0.5em;
  bottom: 1.5em;
  position: fixed;
}

#editButton {
  position: fixed;
  margin: 285px 0 0 15px;
  --border-radius: 8px !important;
  --background: #3b6692;
  height: 40px;
  width: 70px;
  box-shadow: 5px 10px 10px #000000;
  text-transform: capitalize;
}

#finishButton {
  position: fixed;
  margin: 345px 0 0 15px;
  --border-radius: 8px !important;
  --background: #3b6692;
  height: 40px;
  width: 70px;
  box-shadow: 5px 10px 10px #000000;
  text-transform: capitalize;
}

#pinImage {
  position: fixed;
  width: 20px;
  height: 25px;
  margin-top: 0px;
}

#areaImage {
  position: fixed;
  width: 31px;
  height: 31px;
}

#undoImage {
  position: fixed;
  width: 30x;
  height: 30px;
}

#styleImage {
  position: fixed;
  width: 30x;
  height: 30px;
}



.ion-padding {
  --background: rgb(255, 255, 255) !important;
  --color: #000000;
}

.content-ltr {
  
}

#login-button{
  position: unset;
}

.connections-login ion-grid{
  max-width:40em;
}

.connections-login ion-input{
  margin:0.5em;
}



form {
  /* position: absolute;
  width: 50%;
  right: 25%;
  top: 20%; */
}

ion-item {
  border-radius: 0.25em;
  justify-content: flex-end !important;
  align-items: center !important;
  background-color: transparent;;
}

ion-input {
  --background:var(--ion-color-light-shade) !important;
  border: 1px solid var(--ion-color-secondary-tint) !important;
  border-radius: 0.25em;
  --padding-start: 12px !important;
  color: var(--ion-color-light-contrast) !important;
}


div.formInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 1em;
}

div.inlineInputs {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.connections-type-selected{
  --background: var(--ion-color-secondary);
}

.connections-type-unselected{
  --background: var(--ion-color-light-shade);
}

ion-button{
  height: auto;
  width: auto;
}

.ion-hidden{
  display:none;
}

ion-button.connections-button{
  width: auto;
  height: auto;
  --background: var(--ion-color-secondary);
  --background-activated: var(--ion-color-tertiary) !important;
  --border-radius: 0.5em !important;
  aspect-ratio: 1.0;
}

.connections-device-type .connections-device-icon{
  color: var(--ion-color-tertiary-contrast);
  margin-right: 0.25em;
}

.connections-device-type ion-button{
  width: 100%;
  font-size: x-large;
  text-transform: none;
  letter-spacing: 0;
  font-weight: bold;
  --padding-bottom: 1em;
  --padding-top: 1em;
  border-radius: 0.5em;
  --background:var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}

.connections-modal-button{
  font-size: x-large;
  text-transform: none;
  letter-spacing: 0;
  font-weight: bold;
  --background: var(--ion-color-tertiary);
  --padding-bottom: 1em;
  --padding-top: 1em;
  border-radius: 0.5em;
  width:100%;
  color: var(--ion-color-tertiary-contrast);
}

.connections-modal-delete-button{
  --background: var(--ion-color-danger);
}

#connections-device-palette ion-fab-button.connections-device-palette-entry{
  --background: var(--ion-color-secondary);
  --color : var(--ion-color-secondary-contrast) !important;
  font-size: 1.25em;
}

.connections-default-alert{
  --background:var(--ion-color-tertiary);
}

.connections-default-alert .alert-message{
  color : var(--ion-color-secondary-contrast) !important;
  opacity:0.7;
}

.connections-default-alert .alert-button-group{
  background-color:var(--ion-color-tertiary-shade);
  border-top: 1px solid var(--ion-color-tertiary-tint);
}

.connections-default-alert .alert-button{
  color:var(--ion-color-tertiary-contrast);
  border: 1px solid var(--ion-color-tertiary-tint);
}

.connections-default-alert .alert-button-role-confirm{
  font-weight: bold;
}

div.connections-button-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

div.typeButton {
  display: flex;
  flex-direction: column;
  width: 110px;
  align-items: center;
  gap: 8px;
}


.label {
  font-size: x-large !important;
  color: #000000 !important;
  font-weight: bold;
}

.sublabel {
  font-size: medium !important;
  color: var(--ion-color-light-contrast) !important;

}


div.connectionPhaseContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

div.connectionVoltageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

button.connections-submit{
  background-color: var(--ion-color-primary) !important;
  padding: 1em;
  border-radius: 0.25em;
}

.connections-submit {
  --background-color: var(--ion-color-primary) !important;
  font-size: larger;
  padding: 0;
  align-self: center;
  text-transform: none;
  letter-spacing: 0;
  margin: 0;
  --padding-top: .5em;
  --padding-bottom: .5em;
  --padding-left: 2em;
  --padding-right: 2em;
}

.connections-form{
  --background: var(--ion-color-light);
  opacity: 0.95;
  border-radius : 0.25em;
  border: 2px solid var(--ion-color-secondary) !important;
}

form.connections-info-form {
  /* max-height: 80%;
  max-width: 50%; */
  overflow-y: auto;
}

#devicesMenu {
  position: absolute;
  top: 10em;
  right: 6em;
}

div.connections-device-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 17px;
  margin: 1em 0 1.3em 0;
  width: 16em;
}

#deviceLabel {
  width: 100%;
  text-align: center;
}


div.connections-device-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.connections-parameters{
  display: flex;
}


#inputCapacity {
  width: 5em;
  --padding-top: 6%;
  --padding-bottom: 5%;
  border-radius: 0.25em;
  color: var(--ion-color-light-contrast);
}

.connections-device-properties{
  
  display: flex;
  align-content: center;
  align-items: center;
}

.connections-device-properties-headers{
  display:flex;
  flex-direction: column;
  top: -1em;
  position: relative;
}

.connections-device-properties-container ion-col{
  align-items: center;
    align-content: center;
    display: flex;
}

.connections-device-properties-container ion-row{
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.connections-device-properties-container ion-row:last-child{
  border-bottom: none;
}

#downloadLink {
  position: relative;
  height: 30px;
  bottom: 2px;
  left: 54px;
}

#downloadButton {
  border-radius: 15px;
  box-shadow: 0 3px 9px #444444;
}

#downloadButton:hover {
  background-color: #aaaaaa;
}


#inputFile {
  position: fixed;
  left: 15px;
  top: 92%;
}

.connections-map-device-marker-container{
  border-radius: 50%;
  font-size: 3em;
  background-color: var(--ion-color-tertiary);
  border: 1px solid var(---ion-color-tertiary-contrast);
  color: var(--ion-color-tertiary-contrast);
  opacity: 0.9;
  width: 1em;
  height: 1em;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connections-map-device-marker{
  font-size: 0.5em;
  opacity: 1.0;
  position: relative;
}

.connections-close{
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    font-size: x-large;
    --background: none;
    border: none;
    --box-shadow: none;
    color: var(--ion-color-primary);
    --background-hover-opacity:0;
}

.connections-continue-button-container{
  display:flex;
  justify-content: center;
}

.connections-close:hover{
  --background: none !important;
  color:var(--ion-color-primary-tint);
}

.connections-continue-button{
  position: absolute;
  bottom:4em;
  /* right:1em; */
  font-size: 1.5em;
  text-transform: none;
  letter-spacing: 0;
  padding: 0.25em;
  --border-radius: 0.5em;
  --padding-top:0.5em;
  --padding-bottom:0.5em;
  --background: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}


/* Override default control style */
.mapbox-gl-draw_ctrl-bottom-left,
.mapbox-gl-draw_ctrl-top-left {
  margin-left:0;
  border-radius:0 4px 4px 0;
}
.mapbox-gl-draw_ctrl-top-right,
.mapbox-gl-draw_ctrl-bottom-right {
  margin-right:0;
  border-radius:4px 0 0 4px;
}
.mapbox-gl-draw_ctrl-draw {
  background-color:rgba(0,0,0,0.75);
  border-color:rgba(0,0,0,0.9);
}
.mapbox-gl-draw_ctrl-draw > button {
  border-color:rgba(0,0,0,0.9);
  color:rgba(255,255,255,0.5);
  width:30px;
  height:30px;
}
.mapbox-gl-draw_ctrl-draw > button:hover {
  background-color:rgba(0,0,0,0.85);
  color:rgba(255,255,255,0.75);
}
.mapbox-gl-draw_ctrl-draw > button.active,
.mapbox-gl-draw_ctrl-draw > button.active:hover {
  background-color:rgba(0,0,0,0.95);
  color:#fff;
}
.mapbox-gl-draw_ctrl-draw-btn {
  background-repeat: no-repeat;
  background-position: center;
}

.mapbox-gl-draw_point {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m10 2c-3.3 0-6 2.7-6 6s6 9 6 9 6-5.7 6-9-2.7-6-6-6zm0 2c2.1 0 3.8 1.7 3.8 3.8 0 1.5-1.8 3.9-2.9 5.2h-1.7c-1.1-1.4-2.9-3.8-2.9-5.2-.1-2.1 1.6-3.8 3.7-3.8z"/>%3C/svg>');
}
.mapbox-gl-draw_polygon {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/>%3C/svg>');
}
.mapbox-gl-draw_line {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m13.5 3.5c-1.4 0-2.5 1.1-2.5 2.5 0 .3 0 .6.2.9l-3.8 3.8c-.3-.1-.6-.2-.9-.2-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c0-.3 0-.6-.2-.9l3.8-3.8c.3.1.6.2.9.2 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5z"/>%3C/svg>');
}
.mapbox-gl-draw_trash {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z"/>%3C/svg>');
}
.mapbox-gl-draw_uncombine {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m12 2c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l1 1c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-1-1c-.2-.2-.4-.3-.7-.3zm4 4c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l1 1c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-1-1c-.2-.2-.4-.3-.7-.3zm-7 1c-1 0-1 1-.5 1.5.3.3 1 1 1 1l-1 1s-.5.5 0 1 1 0 1 0l1-1 1 1c.5.5 1.5.5 1.5-.5v-4zm-5 3c-.3 0-.5.1-.7.3l-1 1c-.4.4-.4 1 0 1.4l4.9 4.9c.4.4 1 .4 1.4 0l1-1c.4-.4.4-1 0-1.4l-4.9-4.9c-.1-.2-.4-.3-.7-.3z"/>%3C/svg>');
}
.mapbox-gl-draw_combine {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="M12.1,2c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l4.9,4.9c0.4,0.4,1,0.4,1.4,0l1-1 c0.4-0.4,0.4-1,0-1.4l-4.9-4.9C12.6,2.1,12.3,2,12.1,2z M8,8C7,8,7,9,7.5,9.5c0.3,0.3,1,1,1,1l-1,1c0,0-0.5,0.5,0,1s1,0,1,0l1-1l1,1 C11,13,12,13,12,12V8H8z M4,10c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l1,1c0.4,0.4,1,0.4,1.4,0l1-1c0.4-0.4,0.4-1,0-1.4 l-1-1C4.5,10.1,4.3,10,4,10z M8,14c-0.3,0-0.5,0.1-0.7,0.3l-1,1c-0.4,0.4-0.4,1,0,1.4l1,1c0.4,0.4,1,0.4,1.4,0l1-1 c0.4-0.4,0.4-1,0-1.4l-1-1C8.5,14.1,8.3,14,8,14z"/>%3C/svg>');
}

.mapboxgl-map.mouse-pointer .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: pointer;
}
.mapboxgl-map.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mouse-add .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: crosshair;
}
.mapboxgl-map.mouse-move.mode-direct_select .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.mapboxgl-map.mode-direct_select.feature-vertex.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mode-direct_select.feature-midpoint.mouse-pointer .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: cell;
}
.mapboxgl-map.mode-direct_select.feature-feature.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mode-static.mouse-pointer  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.mapbox-gl-draw_boxselect {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: rgba(0,0,0,.1);
    border: 2px dotted #fff;
    opacity: 0.5;
}

.mapboxgl-ctrl-geocoder{
  width: auto;
  min-width: auto;
}